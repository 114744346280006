
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SelectInterface, StatusAct, } from '@/utils/Select'
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'

@Component({
  computed: {
    ...mapGetters('AuthModule', ['getUserRole',]),
    ...mapGetters('MaintenanceModule', ['CompanyActivities',]),
  },
})
export default class SelectState extends Vue {
  @Prop({ required: false, default: 'pending', }) status!: string
  @Prop({ required: false, default: 'pending', }) value!: string
  @Prop({ required: false, }) watchAux!: boolean

  public valid_transitions: SelectInterface[] = []
  public allowed_transitions: string[] | undefined
  public status_data = StatusAct
  public status_selected = 'pending'
  public indexStatus = 0

  private status_selected_aux = ''
  private role = this.$store.state.AuthModule.AuthState.role

  private someHandler () {
    this.$emit('input', this.status_selected)
  }

  public isAllowedTransition (status_value: string) {
    return this.allowed_transitions?.includes(status_value)
  }

  get getStatusVariant () {
    switch (this.status_selected) {
    case 'pending':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    case 'progress':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    case 'hackmetrix_review':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    case 'company_review':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    case 'hackmetrix_approved':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    case 'done':
      this.indexStatus = this.status_data
        .map(function (e) {
          return e.value
        })
        .indexOf(this.status_selected)

      return this.role.includes('Implementador')
        ? this.status_data[this.indexStatus].transition?.implementer
        : this.status_data[this.indexStatus].transition?.client

    default:
      return []
    }
  }

  mounted () {
    if (this.status) {
      this.status_selected = this.status
      this.status_selected_aux = this.status_selected
    }

    this.valid_transitions = this.status_data.filter((s) => this.isAllowedTransition(s.value))

    if (this.status_selected_aux == 'review' && this.role == 'implementer') {
      this.status_selected_aux = 'error'
    }
  }

  @Watch('status')
  getValue () {
    if (this.status) {
      this.status_selected = this.status
      this.status_selected_aux = this.status_selected
    }

    if (this.status_selected_aux == 'review' && this.role == 'implementer') {
      this.status_selected_aux = 'error'
    }
  }

  @Watch('watchAux')
  getValueWatch () {
    this.status_selected = this.status
  }
}
