
import { Component, Vue, Prop, } from 'vue-property-decorator'

@Component({})
export default class ButtonDownload extends Vue {
  @Prop({ required: true,  }) link!: string
  @Prop({ required: true,  }) value!: string

  public fileIcon = ''

  created () {
    this.fileIcon = '../../../assets/icons/file-icons/'
    if (
      this.value.split('.').pop() === 'jpg' ||
      this.value.split('.').pop() === 'jpeg' ||
      this.value.split('.').pop() === 'png' ||
      this.value.split('.').pop() === 'gif')
    {
      this.fileIcon = 'image'
    }
    else if (this.value.split('.').pop() === 'doc' ||
      this.value.split('.').pop() === 'docx' || this.value.split('.').pop() === 'txt') {
      this.fileIcon = 'word'
    }
    else if (this.value.split('.').pop() === 'pdf' ) {
      this.fileIcon = 'pdf'
    }        
    else if (this.value.split('.').pop() === 'xls' ||
      this.value.split('.').pop() === 'xlsx' ) {
      this.fileIcon = 'excel'
    }   
    else {
      this.fileIcon = 'image'
    }
  }
}
