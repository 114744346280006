
import { Vue, Component } from 'vue-property-decorator'
import TaskListContainerComponent from '@/components/TaskListContainer/Index.vue'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import { mapGetters } from 'vuex'
import InputHackmetrix from '@/components/InputHackmetrix/Index.vue'
import SelectState from '@/components/SelectState/Index.vue'
import InputUrl from '@/components/InputUrl/Index.vue'
import ButtonInputUrl from '@/components/ButtonInputUrl/Index.vue'
import ButtonDownload from '@/components/ButtonDownload/Index.vue'
import CommentAreaComponent from '@/components/CommentArea/Index.vue'
import CommentBoxComponent from '@/components/CommentBox/Index.vue'
import Datepicker from '@/components/Calendar/Index.vue'
import { UrlsInterface } from '@/store/modules/maintenance/MaintenanceModule'
import JustLogsModal from '@/components/JustLogsModal/Index.vue'
import NavigationDocument from '@/components/V2/NavigationDocuments/Index.vue'
import Avatar from 'vue-avatar'
import SideNavDocuments from '@/components/V2/SideNavDocuments/Index.vue'
import { differenceInCalendarYears, differenceInCalendarMonths } from 'date-fns'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import ModalActivityComponent from "@/components/ModalActivity/Index.vue";
import ImgViewer from "@/components/FileViewer/ImgViewer.vue";
interface ViewerData {
  name: string;
  url: string;
  extension: string;
}
@Component({
  components: {
    TaskListContainerComponent,
    CommentBoxComponent,
    CommentAreaComponent,
    ChipHackmetrix,
    InputHackmetrix,
    SelectState,
    InputUrl,
    ButtonInputUrl,
    Datepicker,
    ButtonDownload,
    JustLogsModal,
    SideNavDocuments,
    NavigationDocument,
    Avatar,
    OverlayBackground,
    ModalActivityComponent,
    ImgViewer
  },
  computed: {
    ...mapGetters('MaintenanceModule', [
      'getSelectedActivity',
      'ActivitiesByFrimework',
      'getComments',
      'getCompanyMaintenance',
      'CompanyActivities',
    ]),
    ...mapGetters('MaintenanceModule', { selectedActivity: 'getSelectedActivity' }),
    getCompleted() {
      if (this.$store.state.MaintenanceModule.MaintenanceState.SelectedActivity.tasks) {
        const aux =
          this.$store.state.MaintenanceModule.MaintenanceState.SelectedActivity.tasks.filter(
            (task: any) => task.completed
          )
        return aux.length
      } else {
        return 0
      }
    },
    ...mapGetters('DocumentModule', ['getDocumentDetailById']),
    ...mapGetters('DocumentModule', { urls: 'getUrlArray' }),
  },
  methods: {
    getFileType(file: any) {
      const type = file.split('.').pop()

      const images = ['jpg', 'jpeg', 'png', 'gif', 'svg']
      const docs = ['doc', 'docx', 'txt']
      const ppt = ['ppt', 'pptx']
      const pdf = ['pdf']
      const excel = ['xls', 'xlsx']

      if (images.includes(type)) {
        return require('@/assets/icons/file-icons/file-img.svg')
      } else if (docs.includes(type)) {
        return require('@/assets/icons/file-icons/word.svg')
      } else if (ppt.includes(type)) {
        return require('@/assets/icons/file-icons/word.svg')
      } else if (pdf.includes(type)) {
        return require('@/assets/icons/file-icons/file-pdf.svg')
      } else if (excel.includes(type)) {
        return require('@/assets/icons/file-icons/excel.svg')
      } else {
        return require('@/assets/icons/file-icons/word.svg')
      }
    },
  },
})
export default class MaintenanceDetailComponent extends Vue {
  public toExpiredDocument = false
  public showData = true
  public selectedActivity: any
  public openComments = true
  public open = true
  public showDatePickers = false
  public url_detail = ''
  public activeJustLogs = false
  private username =
    this.$store.state.AuthModule.AuthState.name +
    ' ' +
    this.$store.state.AuthModule.AuthState.last_name

  private status = ''
  private expire_at = ''
  private released_at = ''
  private version = this.$store.state.DocumentModule.DocumentState.SelectedDocument.version
  private updated_at = ''
  private role = this.$store.state.AuthModule.AuthState.role
  private urls_array: UrlsInterface[] = [
    {
      link: '',
    },
  ]
  private loading = false

  private urls: any
  private expirationDate =
    this.$store.state.DocumentModule.DocumentState.SelectedDocument.expirationDate
  private redDate = 'red-date'
  private yellowDate = 'yellow-date'

  private description = this.$store.state.DocumentModule.DocumentState.SelectedDocument.description

  // Vars Japod
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private responseData: any = {}

  private urls_new_array: UrlsInterface[] = []
  private responsableId = 0
  private responsableName = ''
  private typeDocument = 0
  private showDocument = false

  private documents: any[] = []
  private documentsApproval: any[] = []
  private documentsCommunication: any[] = []
  private documents1: any[] = []
  private documentsApproval1: any[] = []
  private documentsCommunication1: any[] = []
  private showViewer: {[key: string]: boolean} = {};
  private currentViewer: ViewerData | null = null;
  private imageExtensions = ["jpg", "jpeg", "png", "pdf"];
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private closeDialog() {
    this.activeJustLogs = false
  }
  

  mounted() {
    this.$store.commit('PolimorficModule/SET_STATUS_MODAL', false)
    this.getActivityDetail()
    this.getDateExpired()
    this.toExpiredDocument = this.$store.state.ActivitiesModule.ActivitiesState.SelectedActivity.toExpired
  }

openImgViewer(chip: any) {
  this.currentViewer = {
    name: chip.name.trim(),
    url: chip.urlDownload,
    extension: chip.extension,
  };
    }
    closeViewer() {
      this.currentViewer = null;
    }
  private goToHistory() {
    this.$store.commit('PolimorficModule/SET_STATUS_MODAL', true)
  }

  private sendActsDescription(template: string) {
    this.description = template
    this.loading = true
    const data = {
      company_id: this.Business.id,
      act_id: this.$route.params.activity_id,
      description: this.description,
    }
    this.$store.dispatch('StepperDocumentModule/updateWithoutChangeDocuments', data).then(() => {
      this.loading = false
      this.getActivityDetail()
      this.closeDialog()
    })
  }

  private diffDays = (value: any) => {
    const dateNow = new Date()
    const expireDate = new Date(value)
    const diffTime = expireDate.valueOf() - dateNow.valueOf()
    const diffDays = diffTime / (1000 * 60 * 60 * 24)
    return diffDays
  }

  private diffMonths = (value: any) => {
    const dateNow = new Date()
    const expireDate = new Date(value)
    const difference = differenceInCalendarMonths(dateNow, expireDate)
    return difference
  }

  private getDateExpired() {
    const nowDate = new Date()
    const expireDate = new Date(this.expirationDate)

    const result = differenceInCalendarYears(nowDate, expireDate)

    // if (
    //   expireDate.getFullYear() <= nowDate.getFullYear() &&
    //   expireDate.getMonth() < nowDate.getMonth()
    // ) {
    //   return this.redDate
    // }
    if (result > 1) {
      return this.redDate
    }

    if (expireDate.getFullYear() == nowDate.getFullYear()) {
      const monthsDiff = expireDate.getMonth() - nowDate.getMonth()

      if (monthsDiff >= 0 && monthsDiff <= 3) {
        return this.yellowDate
      }
    }

    return false
  }

  private goSteps() {
    this.loading = true
    // New Dispatch para empezar una actividad Documentada
    const data = {
      companyId: this.Business.id,
      activityId: Number(this.$route.params.activity_id),
      responsableId: this.responsableId,
      responsableName: this.responsableName,
      urls: this.urls,
    }
    this.$mixpanel.track('Actualizar documentacion', {
      Actividad: this.$store.state.DocumentModule.DocumentState.SelectedDocument.activityName,
    })
    this.$store.dispatch('StepperDocumentModule/postCreateEvidenceDoc', data).then(() => {
      this.loading = false
      this.$router
        .push({
          name: 'steppsDocuments',
          params: { activity_id: this.$route.params.activity_id },
        })
        .catch((error) => {
          if (error.name != 'NavigationDuplicated') return
        })
      this.$emit('refresh')
    })

    // END new Dispatch
  }

  private actUrl(value: string) {
    this.urls_new_array.push({ url: value })
  }

  private actUser(value: any) {
    this.responsableId = value.id
    this.responsableName = value.full_name
  }
  private redirectToDocuments(data: any) {
    const routeName = !this.ADMIN_ROLES.some((role:any) => this.role.includes(role))
    ? 'steppsDocumentsClient'
    : 'steppsDocuments';

    this.$router
      .push({
        name: routeName,
        params: { activity_id: this.$route.params.activity_id },
      })
      .catch((error) => {
        if (error.name != 'NavigationDuplicated') return
      });
  }

  private getActivityDetail() {
    this.loading = true
    this.showData = false
    this.getActivityComments()

    const data = {
      company_id: this.Business.id,
      act_id: this.$route.params.activity_id,
    }

    this.$store
      .dispatch('DocumentModule/getDocumentById', data)
      .then((response) => {
        const data = response.data.data
        this.responseData = data

        if (data.version > 1) {
          this.$store.dispatch(
            'StepperDocumentModule/getHistoryDocument',
            this.$route.params.activity_id
          )
        }
        if (data.status === 'in_progress' || (data.step && data.step.queryName !== 'InitialStep')) {
          this.loading = false
          this.showData = true
          this.redirectToDocuments(data);
        } else {
          this.loading = false
          this.showData = true
        }

        this.validTypeDocument()

        data.documents.forEach((element: any) => {
          if (element.featureType === 'documentation') {
            this.documents.push(element)
          } else if (element.featureType === 'approval') {
            this.documentsApproval.push(element)
          } else if (element.featureType === 'communication') {
            this.documentsCommunication.push(element)
          }
        })

        this.showDocument = true
        this.documents1 = this.showFilesHistory(this.documents[0]) ? this.documents[0].history[0].files : [];
        this.documentsApproval1 = this.showFilesHistory(this.documentsApproval[0]) ? this.documentsApproval[0].history[0]?.files : [];
        this.documentsCommunication1 = this.showFilesHistory(this.documentsCommunication[0]) ? this.documentsCommunication[0].history[0].files : [];
      })
      .catch((error) => {
        this.loading = false
        this.showData = true
      })
  }

  private validDone() {
    this.activeJustLogs = true
  }

  private openUrl(act: any) {
    window.open(act['issues'][0]['url'], '_blank')
  }

  private validTypeDocument() {
    switch (this.responseData.documentTypes.length) {
      // Documento Completo
      case 3:
        this.typeDocument = 3
        break
      // Documento solo
      case 2:
        this.typeDocument = 2
        break
      // Documento con Comunicacion
      case 1:
        this.typeDocument = 1
        break

      default:
        this.typeDocument = 3
        break
    }
  }

  // FUNCIONES VIEJAS

  private urlsAct(value: string, index: number) {
    if (!value.trim()) {
      this.urls_array.splice(index, 1)
    } else {
      this.urls_array[index].link = value
    }
  }

  private sendMessage(message: string) {
    this.loading = true
    this.showData = false
    this.$store
      .dispatch('MaintenanceModule/postActivitiesComment', {
        act_id: this.$route.params.activity_id,
        message,
      })
      .then(() => {
        this.loading = false
        this.showData = true
        this.getActivityDetail()
      })
      .catch(() => {
        this.loading = false
        this.showData = true
        this.getActivityDetail()
      })
  }

  private getActivityComments() {
    this.$store.dispatch('MaintenanceModule/getActivitiesComments', this.$route.params.activity_id)
  }

  private showFilesHistory(documents: any) {
    try {
      return documents.history[0].files.length > 0;
    } catch (e) {
      return false;
    }
  }
}
